.FooterSecondPart {
    max-width: 1120px;
    margin: 0 auto;
    padding: 40px 40px 15px 40px;
    display: flex;
    position: relative;
    color: white;
  }
  .FooterLogoPart {
    width: 25%;
    margin: 0 auto;
    padding: 0px 0px 0px 40px;
    display: flex;
    position: relative;
    color: white;
  }
 
  .FooterSecondPartCenter {
    max-width: 1120px;
    margin: 0 auto;
    padding: 40px 40px 15px 40px;
    display: inline-block;
    position: relative;
    color: white;
  }
  .besignedByName{
    text-decoration: underline;
    text-decoration-thickness: 1px;
    font-size: 12px;
    width: max-content;
  }

  .Footer{
    background-color: black;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
  }

  .list {
    text-align: start;
    font-size: 14px;
    color: #666e70;
    padding: 15px;
  }

  .headingFooter {
    font-weight: 700 !important;
    font-size: 17px;
    color: white;
    margin: revert;
  }

  .conatctUs{
    width: 35%;
    text-align: start;
  }
  .companyInfoFooter{
    width: 50%;
    text-align: start;
  }

  .contactUsList{
    display: flex;
    padding: 10px 0px 0px 0px;
    color: #b6c1c4;
  }
  .contactKey{
    font-size: 14px;
    margin-left: 6px;
    width: 171px;
  }
  .conatctValue{
    font-size: 14px;
  }
  .designedBy{
    display: -webkit-box;
    font-size: 12px;
  }
.designedByH1{
  font-size: 12px;
  width: max-content;
  display: contents;
}
  .designedBySection{
    width: 35%;
    text-align: start;
    place-self: self-end;
  }
  .FooterLogoContainer{
    text-align:center;
    width: 100%;
    /* background-color: #2eacdf; */
    background: linear-gradient(to bottom, #0057fe 0%, #00a0e3 100%);
    padding-top: 40px
  }

  .FooterUserLogo{
    font-size: 35px;
    font-weight: bold;
    display: inline-flex;
  }

  .FooterCompanyInfo{
    padding: 10px 35px 35px;
  }

  .socialLogoIcons{
    text-align:center;
    width: 100%;
    width: 40px;
    height: 40px;
    background-color: #00a0e3
  }

  @media (max-width: 700px) {
    .what-we-offer-list {
      width: 100%;
      margin-top: 15px;
    }
    .FooterSecondPart {
      max-width: 1120px;
      margin: 0 auto;
      padding: 20px 40px;
      position: relative;
      color: white;
      display: block;
      width: max-content;
    }
    .FooterLogoContainer{
      text-align:center;
      width: 100%;
      /* background-color: #2eacdf; */
    background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);

    }
    .FooterLogoPart {
      width: 90%;
      margin: 0 auto;
      padding: 0px 0px 0px 20px;
      display: block;
      position: relative;
      color: white;
    }
    .what-we-offer-element {
      width: 100%;
      display: flex;
      padding: 20px;
    }
    .conatctUs{
        width: 100%;
        text-align: start;
      }
      .companyInfoFooter{
        width: 100%;
        text-align: start;
      }
    .contactUsList{
        font-size: 14px;
        color: #b6c1c4;
        padding: 10px 0px 0px 0px;
      }
      .designedBySection {
        text-align: start;
        place-self: self-end;
        display: none;
    }
    .Footer{
      background-color: black;
      position: absolute;
      bottom: 0;
      width: 100%;
      display: block;
    }
   
  }
