.wwdaboutUs{
    display: flex;
    background-color: rgba(225, 224, 224, 0.32);
  }
  .TalentAcquisitionPart {
    max-width: 1120px;
    margin: 0 auto;
    padding: 60px 40px 40px 40px;
    position: relative;
    margin-bottom: 370px
  }
  .what-we-offer-heading {
    font-weight: 700 !important;
    font-size: 25px;
    color: black
  }

  .what-we-do-block{
    text-align: left;
  }

  .wwdItem {
    float: left;
    /* width: 57%; */
    padding: 20px 65px;
    background: none;
    margin: 10px;
    /* height: 185px; */
    border-radius: 10px;
    transition: all .5s;
    background-size: 200%;
    background-image: linear-gradient(to right, #fff 50%, #00a0e3 
    /* rgb(114, 214, 248) */
     50%);
  }
  .whatWeDoHeader{
    font-size: 18px;
    font-weight: bold;
    color: #00a0e3;
  }

  .wwddis{
    text-indent: 50px;
    color: #666e70
  }
  .wwdlistDes{
    margin-top: 20px;
    margin-bottom: 10px;
    color: #666e70;
  }


  .wwdlist{
    text-align: start;
    font-size: 14px;
    color: #666e70;
    padding: 10px;
  }
  .wwdsublist{
    text-align: start;
    font-size: 14px;
    color: #666e70;
    padding: 5px;
  }


  @media (max-width: 767px) {
    .TalentAcquisitionPart {
      max-width: 1120px;
      margin: 0 auto;
      padding: 20px 40px;
      position: relative;
      margin-bottom: 1045px

    }
    .what-we-offer-heading {
        font-weight: 700 !important;
        font-size: 25px;
        color: black
      }
      .wwdItem {
        float: left;
        width: 57%;
        padding: 20px 65px;
        background: none;
        margin: 10px;
        border-radius: 10px;
        transition: all .5s;
        background-size: 200%;
        background-image: linear-gradient(to right, #fff 50%, #00a0e3 
         50%);
      }
  }

  @media (max-width: 991px)and(min-width:768px) {
    .wwdItem {
      float: left;
      width: 89%;
      padding: 20px 65px;
      background: none;
      margin: 10px;
      /* height: 185px; */
      border-radius: 10px;
      transition: all .5s;
      background-size: 200%;
      background-image: linear-gradient(to right, #fff 50%, #00a0e3 
      /* rgb(114, 214, 248) */
       50%);
    }
  }

  @media(min-width: 992px){
    .wwdItem {
      float: left;
      width: 34%;
      padding: 20px 65px;
      background: none;
      margin: 10px;
      /* height: 185px; */
      border-radius: 10px;
      transition: all .5s;
      background-size: 200%;
      background-image: linear-gradient(to right, #fff 50%, #00a0e3 
      /* rgb(114, 214, 248) */
       50%);
    }
  }
  @media (min-width: 1400px) {

    .wwdsublist{
      text-align: start;
      font-size: 14px;
      color: #666e70;
      padding: 10px;
    }

  }