.homeSecondPart {
  max-width: 1120px;
  margin: 0 auto;
  padding: 60px 40px 40px 40px;
  position: relative;
}

.who-are-we-list{
  text-align: start;
    font-size: 14px;
    color: #666e70;
    padding: 15px;
}
.who-are-we{
  background-color: rgba(225, 224, 224, 0.32);
}

.whoweare-ul{
  margin-bottom: 370px;
}

@media (max-width: 700px) {
  .homeSecondPart {
    max-width: 1120px;
    margin: 0 auto;
    padding: 20px 40px;
    position: relative;
  }

  .whoweare-ul{
    margin-bottom: 1045px;
  }
 
}


