.input {
    padding: 10px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid grey;
    font-family:"Open Sans", sans-serif;
}

.textarea{
    padding: 10px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid grey;
    font-family:"Open Sans", sans-serif;
}

.formInputs {
    display: flex;
    flex-direction: column;
    /* width: 280px; */
}

.label {
    color: grey;
    font-size: 12px;
    text-align: start;
    font-family:"Open Sans", sans-serif;
}

.errorMessage {
    color: red;
    font-size: 12px;
    text-align: start;
    padding: 3px;
}

.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input[type=number] {
    -moz-appearance: textfield;
}