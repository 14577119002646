.AboutUsSecondPart {
    max-width: 1120px;
    margin: 0 auto;
    padding: 60px 40px 40px 40px;
    position: relative;
  }

  .aboutUs{
    background-color: rgba(225, 224, 224, 0.32);
  }



  .list {
    text-align: start;
    font-size: 14px;
    color: #666e70;
    padding: 15px;
  }




  .aboutUsImage{
    width: 50%;
    margin-top: 10px;
  }
  .aboutUs-ul{
    margin-bottom: 370px;
  }
  .paragraph{
    text-indent: 70px;
    text-align: start;
    margin-top: 20px
  }




  @media (max-width: 767px) {
    .aboutUs-ul{
      margin-bottom: 1045px;
    }
    .what-we-offer-list {
      width: 100%;
      margin-top: 15px;
    }
    .AboutUsSecondPart {
      max-width: 1120px;
      margin: 0 auto;
      padding: 20px 40px;
      position: relative;
    }
    .what-we-offer-element {
      width: 100%;
      display: flex;
      padding: 20px;
    }
    
      
  }


