.container{
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
}
.rows{
    display: flex;
    place-items: center;
    margin-top: 20px;
}
formBackground{
    display: flex;
justify-content: center;
/* align-items: center; */
/* height: 100vh; */
/* background-color: grey; */
padding: 10px;
}
.form{
    background-color: white;
padding: 0px 60px;
border-radius: 10px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
margin-bottom: 200px;
width: 280px;
}
.formh1{
    text-align: center;
    padding: 16px;
    font-size: 18px;
}
.submitButton{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 30px;
    background-color: #00a0e3;
    color: white;
}
.firstBlock{

}
.boxIcon{
    margin:3px;
    position: relative;
}
.icon{
    font-size: 20px;
}

.circleIcon{
    background: #0ea2e6;
    border-radius: 50%;
    color: black;
    display: inline-block;
    height: 30px;
    font-weight: bold;
    font-size: 1.2em;
    width: 30px;
    margin: 10px;
    position: relative;
}
.thanksMessage{
    font-size: 14px;
    font-weight: bold;
}
.letsWorkTogether{
    padding-bottom: 440px;
    margin-top: -110px;
    font-weight: bold;
    font-style: italic;
    font-family: auto;
    font-size: 25px;
}

@media (max-width: 768px) {
    .container{
        display: block;
        justify-content: space-evenly;
        padding: 10px;
    }
    .rows{
        display: flex;
        place-items: center;
        margin-top: 20px;
        margin-left: 15%;
    }
     .formBackground{
        display: flex;
    justify-content: center;
    padding: 35px;
    }
    .form{
        background-color: white;
    padding: 0px 60px;
    width: 280px;
    }
    .letsWorkTogether{
        padding-bottom: 1095px;
    margin-top: -190px;
    font-weight: bold;
    font-style: italic;
    font-family: auto;
    font-size: 25px;
    }
}
